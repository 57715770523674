import * as React from "react"
import {graphql} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {StaticImage} from "gatsby-plugin-image"
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';

// markup
const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Seo />
      <section className="text-gray-600 body-font mt-10">
        <div className="my-cont flex flex-col items-center justify-center">
          <StaticImage className="lg:w-5/12 md:w-3/6 w-5/12 mb-10 object-cover object-center rounded" src="../images/404.jpg" alt="Not found" />
          <div className="text-center w-full">
            <h1 className="title-font sm:text-4xl text-3xl font-medium text-gray-900">{t('msg.404')}</h1>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "404"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
